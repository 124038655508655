/* pdf_styles.css */
.textTitle {
  color: #373737;
}

.textSubtitle {
  color: #020202;
}

.textBody {
  color: #020202;
}