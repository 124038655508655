/* Estilos para el contenedor general del modal */
.modal-container {
    width: 100%; /* Ancho relativo al contenedor padre */
    max-width: 970px; /* Ancho máximo del modal */
    margin: 0 auto; /* Centra el modal horizontalmente */
    padding: 10px;
    box-sizing: border-box; /* Incluye el padding y el borde en el ancho total */
}

.checkbox-list input[type="checkbox"] {
    margin: 0;
    padding: 0;
}

/* Estilos para el contenedor de la lista de checkboxes */
.checkbox-list {
    display: flex;
    flex-direction: column; /* Muestra los elementos en una columna */
    padding: 10px;
    border: 1px solid #ccc; /* Borde alrededor de la lista */
    border-radius: 4px; /* Esquinas redondeadas */
    background-color: #f9f9f9; /* Color de fondo */
    width: 100%; /* Ancho fijo del contenedor */
    /*max-height: 400px; /* Altura máxima del contenedor */
    box-sizing: border-box; /* Incluye el padding y el borde en el ancho total */
    overflow-y: auto; /* Agrega una barra de desplazamiento vertical si el contenido es mayor que la altura máxima */
    overflow-x: hidden; /* Oculta cualquier contenido que se desborde horizontalmente */
}

/* Estilos para las etiquetas de los elementos de la lista */
.checkbox-list label {
    display: flex; /* Usa flexbox para alinear el contenido */
    align-items: center; /* Alinea verticalmente los items */
    padding: 8px;
    border-bottom: 1px solid #ddd; /* Línea separadora entre items */
    word-wrap: break-word; /* Rompe el texto largo para ajustar al contenedor */
    overflow: hidden; /* Oculta cualquier contenido que se desborde del contenedor */
    white-space: normal; /* Permite el ajuste de texto */
    text-overflow: ellipsis; /* Muestra puntos suspensivos si el texto es demasiado largo */
    line-height: 1.5; /* Ajusta la altura de línea para evitar superposición vertical */
    box-sizing: border-box; /* Incluye el padding en el tamaño total del elemento */
    text-align: justify;
}

/* Elimina la línea en el último elemento */
.checkbox-list label:last-child {
    border-bottom: none;
}

/* Estilos para el checkbox */
.checkbox-list input[type="checkbox"] {
    margin-right: 10px; /* Espacio entre el checkbox y el texto */
}

/* Estilos para el botón de guardar */
.save-button {
    background-color: #4CAF50; /* Color de fondo verde */
    color: white; /* Color del texto */
    border: none; /* Sin borde */
    padding: 15px 32px; /* Espaciado interno */
    text-align: center; /* Centrar el texto */
    text-decoration: none; /* Sin subrayado */
    display: inline-block; /* Alineación en línea */
    font-size: 16px; /* Tamaño del texto */
    margin: 4px 2px; /* Espaciado externo */
    cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
    border-radius: 8px; /* Bordes redondeados */
    transition: background-color 0.3s, transform 0.2s; /* Transiciones suaves */
}

.save-button:hover {
    background-color: #45a049; /* Color de fondo al pasar el ratón */
}

.save-button:active {
    transform: scale(0.98); /* Efecto de pulsación */
}

/* Estilos para el contenedor de la lista de checkboxes */
.checkbox-list-button {
    display: flex;
    flex-direction: column; /* Muestra los elementos en una columna */
    padding: 10px;
    border: 1px solid #ccc; /* Borde alrededor de la lista */
    border-radius: 4px; /* Esquinas redondeadas */
    background-color: #f9f9f9; /* Color de fondo */
    width: 100%; /* Ancho fijo del contenedor */
    max-height: 400px; /* Altura máxima del contenedor */
    box-sizing: border-box; /* Incluye el padding y el borde en el ancho total */
    overflow-y: auto; /* Agrega una barra de desplazamiento vertical si el contenido es mayor que la altura máxima */
    overflow-x: hidden; /* Oculta cualquier contenido que se desborde horizontalmente */
}

.checkbox-list-button:hover {
    background-color: #CCCCCC; /* Color de fondo al pasar el ratón */
}

.checkbox-list-button:active {
    transform: scale(0.98); /* Efecto de pulsación */
}
